import { CountUp } from 'countup.js';
(function () {
  document.addEventListener('DOMContentLoaded', function () {
    const isElementXPercentInViewport = function (el, percentVisible) {
      let rect = el.getBoundingClientRect(),
        windowHeight =
          window.innerHeight || document.documentElement.clientHeight;

      return !(
        Math.floor(
          100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
        ) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
          percentVisible
      );
    };

    if (0 < document.querySelectorAll('.counter-block__counters').length) {
      var counters = document.querySelectorAll('.counter-block__item__number');
      const countUp = CountUp;
      counters.forEach((counter) => {
        var number = counter.innerHTML;
        var animation = new countUp(counter, number);

        function counterAnimation() {
          if (
            isElementXPercentInViewport(
              document.querySelector('.counter-block__counters'),
              50
            )
          ) {
            animation.start();
          }
        }

        document.addEventListener('touchmove', counterAnimation, false);
        document.addEventListener('scroll', counterAnimation, false);
      });
    }
  });
})(jQuery);
